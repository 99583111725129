<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Students')"
      :subtitle="$t('Evaluation')"
      :root="$store.state.courses.course.name"
      :root-location="COURSE_NAVIGATION_RETURN_LINK($store.state.courses.course)"
      icon="mdi-account-multiple"
      :side-navigation="EVALUATION_NAVIGATION"
      navigation-route="evaluation"
      :filters="filtersItems"
      fill-height

      :no-content-condition="items.length === 0"
      :no-content-button-route="businessDashLink('courses/' + this.uuid + '/students')"
      no-content-button-text="Students"
      no-content-text="NoStudentsMessage"
      no-content-icon="mdi-account-multiple"
      :loading="loading"
  >
    <template #default>
      <ws-data-table
          :items="itemsFiltered"
          :headers="headers"
          :search="$store.state.settings.filtersData.search"
          class="pb-8 mt-5"
      >
        <template #item="{item}">
          <tr @click="openStudent(item.uuid)"
              :style="`background : ${ hover ? wsLIGHTCARD : ''} !important`"
              class="pointer"
          >
            <td  :style="`border-color: ${wsBACKGROUND}`" class="pl-0">
              <div class="d-flex align-center text-no-wrap">
                <v-avatar class="mr-2 ml-1"  :color="wsACCENT"
                          size="36" key="profile_picture" >
                  <v-img v-if="item.img" :src="item.img" transition="xxx">
                    <template slot="placeholder">
                      <v-img src="@/assets/placeholder/user_blank.jpg" transition="xxx" />
                    </template>
                  </v-img>
                  <v-icon  dark v-else>mdi-account-tie</v-icon>
                </v-avatar>
                <div>
                  <h5 :style="`color : ${wsACCENT}`">{{ item.name }} </h5>
                  <h6
                      v-if="IS_BUSINESS_OWNER || !MODULE_PERMISSIONS(['MODULE_WESTUDY__HIDE_STUDENT_CONTACTS'], true)"
                      :style="`color : ${wsACCENT}`"> {{ item.email}}</h6>
                </div>
              </div>
            </td>
            <td
                v-if="IS_BUSINESS_OWNER || !MODULE_PERMISSIONS(['MODULE_WESTUDY__HIDE_STUDENT_CONTACTS'], true)"
                align="left">
              <h5>{{ item.phone}}</h5>
            </td>
            <td :style="`border-color: ${wsBACKGROUND}`" width="10" align="center">
              <ws-date-picker custom-activator  @input="editDateEnrolled($event, item)" >
                <ws-chip class="text-no-wrap" :text="item.date || $t('Choose')"  icon="mdi-calendar" >
                  <v-icon small>mdi-chevron-down</v-icon>
                </ws-chip>
              </ws-date-picker>
            </td>
            <td :style="`border-color: ${wsBACKGROUND}`" width="10" align="center">
              <h5 :style="`color : ${item.completion === 100 ? wsSUCCESS : wsACCENT}`">
                <v-icon class="mr-1"  :color="item.completion === 100 ? wsSUCCESS : wsACCENT">
                  mdi-progress-check
                </v-icon>
                {{ item.completion }} %
              </h5>
            </td>
          </tr>
        </template>
      </ws-data-table>
    </template>


  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "evaluationStudents",
  props : {
    uuid : {}
  },
  data() {
    return {
      items : [],
      loading : false,
      enrollmentWavesSelect : [],
      paymentPlansSelect : [],
      groups : []
    }
  },
  computed : {
    headers(){

      let items = [
        { text : this.$t('Name')  , value : 'name'  ,              },

        { text : this.$t('LastAction') , value : 'date' , width : 10, align : 'right'      },
        { text : this.$t('Completion') , value : 'completion' , width : 10, align : 'right'         },
        { value : 'email' , align : 'd-none'}
      ]
      if ( this.IS_BUSINESS_OWNER || !this.MODULE_PERMISSIONS(['MODULE_WESTUDY__HIDE_STUDENT_CONTACTS'], true) ) {
        items.splice(1,0,     { text : this.$t('Phone')  , value : 'phone'           } )
      }

      return items

    } ,
    itemsFiltered() {
      let items = this.items

      let filters = this.$store.state.settings.filtersData
      if (filters.active !== null && filters.active !== undefined ) {
        items = items.filter(el => el.active === filters.active )
      }

      if ( filters.schedule ) {
        let filterValue = filters.schedule !== 'null' ? filters.schedule : null
        items = items.filter( el=> el.enrollment_wave_id === filterValue )
      }

      if ( filters.payment_plan ) {
        let filterValue = filters.payment_plan !== 'null' ? filters.payment_plan : null
        items = items.filter( el=> el.payment_plan_id === filterValue )
      }

      if ( filters.group ) {
        let filterValue = filters.group !== 'null' ? filters.group : null
        items = items.filter( el=> el.group_id === filterValue )
      }

      if ( filters.is_purchased !== null && filters.is_purchased !== undefined  ) {
        items = items.filter( el=> el.is_purchased === filters.is_purchased )
      }

      return items
    },
    groupsFiltered() {
      if (this.groups.length === 0 ) {
        return []
      }
      if (!this.$store.state.courses.course.online) {
        return this.groups.filter(el=> !el.enrollment_wave_id )
      }
      if (this.$store.state.courses.course.online && this.$store.state.courses.course.online) {
        return this.groups.filter(el=> el.enrollment_wave_id )
      }

      return []

    },
    filtersItems() {
      let items = [];

      items = [{text : this.$t('ProfileStatus') , value : 'active' , type : 'select' , items : this.CHOICE_ACTIVE}]

      if (this.$store.state.courses.course.online) {
        let choiceItems  = JSON.parse(JSON.stringify(this.enrollmentWavesSelect))
        choiceItems.push({ text : this.$t('None') , value : 'null' })
        items.push( {text : this.$t('Schedule') , value : 'schedule' , type : 'select' , items : choiceItems } )
      }
      if (this.$store.state.courses.course.payed) {
        items.push( {text : this.$t('PaymentStatus') , value : 'is_purchased' , type : 'select' , items : this.CHOICE_PAYED} )
      }
      if (this.$store.state.courses.course.has_payment_plans) {
        let choiceItems  = JSON.parse(JSON.stringify(this.paymentPlansSelect))
        choiceItems.push({ text : this.$t('None') , value : 'null' })
        items.push( {text : this.$t('PaymentPlan') , value : 'payment_plan' , type : 'select' , items : choiceItems } )
      }

      if ( this.groupsFiltered.length > 0 ) {
        let choiceItems  = JSON.parse(JSON.stringify(this.groupsFiltered))
        choiceItems.push({ text : this.$t('None') , value : 'null' })
        items.push( {text : this.$t('Group') , value : 'group' , type : 'select' , items : choiceItems } )
      }

      return items
    },
  },
  methods : {
    ...mapActions('courses' , ['GET_COURSE_STUDENTS']),

    openStudent(uuid) {
      this.$router.push(this.businessDashLink(`courses/${this.uuid}/evaluation/students/${uuid}`))
    },

    async initPage() {

      this.loading = true
      let result =  await this.GET_COURSE_STUDENTS({uuid : this.uuid})
      this.loading = false
      if ( !result ) {
        return
      }

      result.students.reverse()
      this.items = result.students
      this.groups = result.groups
      this.enrollmentWavesSelect = result.enrollment_waves
      this.paymentPlansSelect = result.payment_plans

      this.$store.state.settings.filtersData = { active : true }



    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>